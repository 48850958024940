<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-map-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualizar mapa: {{ canvas.nome }}
            </div>
          </template>
          <v-row
            align="center"
            no-gutters
            class="mt-3 ml-0"
          >
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="py-1 px-1"
              >
                Exibindo {{ feicoesExibidas | parseNumberToIntegerBR }} de um
                total de {{ totalFeicoes | parseNumberToIntegerBR }} feições.
                Máximo a serem plotadas:
                {{ maximoFeicoes | parseNumberToIntegerBR }} feições. Para
                visualizar mais feições, por favor, utilize a ferramenta
                <router-link :to="'/mapas/entidades-geo-bdgd/visualizar-mapa'"
                  >Entidades geográficas da BDGD</router-link
                >.
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div id="visualizar-mapa-canvas"></div>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CanvasService from '@/services/CanvasService';
import VisualizarMapaMixins from '@/components/canvas/VisualizarMapaMixins';

export default {
  mixins: [VisualizarMapaMixins],

  data() {
    return {
      canvas: {
        nome: ''
      },
      layers: [],
      mapaId: 'visualizar-mapa-canvas',
      timeoutControl: null
    };
  },

  mounted() {
    this.getMapa(this.$route.params.id);

    this.desenhaMapa();

    // Atualiza o bbox quando o usuário finalizar a movimentação do mapa
    this.map.on('moveend', () => {
      // Cancela o request anterior, caso ele exista
      if (this.timeoutControl) clearTimeout(this.timeoutControl);

      // Espera um pouco antes de fazer novamente a requisição, pois o usuário pode movimentar muito o mapa para encontrar um local
      this.timeoutControl = setTimeout(
        () => (this.bboxString = this.map.getBounds().toBBoxString()),
        2000
      );
    });

    if (this.totalFeicoes > this.maximoFeicoes) {
      this.map.setZoom(18);
      return;
    }

    this.plotaLayersMapa();
  },

  computed: {
    maximoFeicoes() {
      return this.getMapConfigMaxFeatures ?? 5000;
    }
  },

  methods: {
    getMapa(id) {
      CanvasService.getCanvasById(id)
        .then((response) => {
          this.canvas = response.data.data;
          this.layers = this.canvas.layers;
        })
        .catch(() =>
          this.$toast.error('Erro ao recuperar o mapa.', '', {
            position: 'topRight'
          })
        );
    }
  }
};
</script>

<style>
#visualizar-mapa-canvas {
  height: 70vh;
  z-index: 0;
}

.mapa-info {
  padding: 6px 8px;
  font:
    14px/16px Arial,
    Helvetica,
    sans-serif;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.mapa-info h4 {
  margin: 0 0 5px;
  color: #777;
}

.mapa-legend {
  line-height: 18px;
  color: #555;
  max-height: 225px !important;
  overflow-y: auto !important;
}

.mapa-legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.7;
}
</style>
